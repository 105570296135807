import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ee309a46 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "" */))
const _2ad842e2 = () => interopDefault(import('../pages/fair/index.vue' /* webpackChunkName: "" */))
const _e7cb921c = () => interopDefault(import('../pages/fair/index-seo.vue' /* webpackChunkName: "" */))
const _2e69176e = () => interopDefault(import('../pages/fair/exporec.vue' /* webpackChunkName: "" */))
const _7203dc6e = () => interopDefault(import('../pages/wiki/index.vue' /* webpackChunkName: "" */))
const _f2284c9c = () => interopDefault(import('../pages/wiki/list.vue' /* webpackChunkName: "" */))
const _66cac0e5 = () => interopDefault(import('../pages/wiki/detail.vue' /* webpackChunkName: "" */))
const _2ef5f8c8 = () => interopDefault(import('../pages/fair/top100.vue' /* webpackChunkName: "" */))
const _47eb8710 = () => interopDefault(import('../pages/fair/detail1.vue' /* webpackChunkName: "" */))
const _af1897c0 = () => interopDefault(import('../pages/fair/detail1-seo.vue' /* webpackChunkName: "" */))
const _7edb7902 = () => interopDefault(import('../pages/fair/pdf.vue' /* webpackChunkName: "" */))
const _37d79226 = () => interopDefault(import('../pages/fair/periodical.vue' /* webpackChunkName: "" */))
const _bef6c8f2 = () => interopDefault(import('../pages/fair/periodical_new.vue' /* webpackChunkName: "" */))
const _f1bd3cda = () => interopDefault(import('../pages/booth/index1.vue' /* webpackChunkName: "" */))
const _31e352a3 = () => interopDefault(import('../pages/trip/index.vue' /* webpackChunkName: "" */))
const _66617401 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "" */))
const _48b5c18e = () => interopDefault(import('../pages/exhDetailInf/index.vue' /* webpackChunkName: "" */))
const _19656c67 = () => interopDefault(import('../pages/fair/proxyExh.vue' /* webpackChunkName: "" */))
const _2ee20a6b = () => interopDefault(import('../pages/company/list.vue' /* webpackChunkName: "" */))
const _2c10b144 = () => interopDefault(import('../pages/company/detail.vue' /* webpackChunkName: "" */))
const _fa58b3a8 = () => interopDefault(import('../pages/fair/ticket.vue' /* webpackChunkName: "" */))
const _50aa9d96 = () => interopDefault(import('../pages/fair/ticket1.vue' /* webpackChunkName: "" */))
const _cd13889e = () => interopDefault(import('../pages/news/index2.vue' /* webpackChunkName: "" */))
const _3fa5a1be = () => interopDefault(import('../pages/pavilion/index.vue' /* webpackChunkName: "" */))
const _4d61a595 = () => interopDefault(import('../pages/pavilion/detail.vue' /* webpackChunkName: "" */))
const _31b1a1e2 = () => interopDefault(import('../pages/news/detail.vue' /* webpackChunkName: "" */))
const _8da7d41c = () => interopDefault(import('../pages/news/detail-seo.vue' /* webpackChunkName: "" */))
const _07149dd4 = () => interopDefault(import('../pages/news/exponews.vue' /* webpackChunkName: "" */))
const _582d6b26 = () => interopDefault(import('../pages/news/exponews-seo.vue' /* webpackChunkName: "" */))
const _35bcd1c4 = () => interopDefault(import('../pages/news/strategy.vue' /* webpackChunkName: "" */))
const _12dd603a = () => interopDefault(import('../pages/news/test.vue' /* webpackChunkName: "" */))
const _ff34c65a = () => interopDefault(import('../pages/cooperation/index.vue' /* webpackChunkName: "" */))
const _268da7bf = () => interopDefault(import('../pages/visa/index.vue' /* webpackChunkName: "" */))
const _437a5fb4 = () => interopDefault(import('../pages/visa/detail.vue' /* webpackChunkName: "" */))
const _785a617a = () => interopDefault(import('../pages/visa/submitOrder.vue' /* webpackChunkName: "" */))
const _520948ce = () => interopDefault(import('../pages/airTicket/index.vue' /* webpackChunkName: "" */))
const _54576152 = () => interopDefault(import('../pages/airTicket/list.vue' /* webpackChunkName: "" */))
const _0bcf2912 = () => interopDefault(import('../pages/airTicket/submitOrder.vue' /* webpackChunkName: "" */))
const _19a05361 = () => interopDefault(import('../pages/airTicket/payResult.vue' /* webpackChunkName: "" */))
const _b22b0bfe = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "" */))
const _26e9d3bb = () => interopDefault(import('../pages/expouser/index.vue' /* webpackChunkName: "" */))
const _2c0972c2 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "" */))
const _2cba487a = () => interopDefault(import('../pages/about/advertise.vue' /* webpackChunkName: "" */))
const _bffc5db6 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "" */))
const _4ed965e6 = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "" */))
const _88825518 = () => interopDefault(import('../pages/pay/payment.vue' /* webpackChunkName: "" */))
const _955c24c2 = () => interopDefault(import('../pages/pay/result.vue' /* webpackChunkName: "" */))
const _4609d1d2 = () => interopDefault(import('../pages/visa/payResult.vue' /* webpackChunkName: "" */))
const _12294bf6 = () => interopDefault(import('../pages/manager/index.vue' /* webpackChunkName: "" */))
const _80f61eb4 = () => interopDefault(import('../pages/manager/fairRemind.vue' /* webpackChunkName: "" */))
const _50521552 = () => interopDefault(import('../pages/manager/fairFollow.vue' /* webpackChunkName: "" */))
const _19e83a41 = () => interopDefault(import('../pages/manager/newsFollow.vue' /* webpackChunkName: "" */))
const _6b16da88 = () => interopDefault(import('../pages/manager/newsMy.vue' /* webpackChunkName: "" */))
const _99f67216 = () => interopDefault(import('../pages/manager/ticketMy.vue' /* webpackChunkName: "" */))
const _4ea2cf5e = () => interopDefault(import('../pages/manager/visa.vue' /* webpackChunkName: "" */))
const _474e5df3 = () => interopDefault(import('../pages/manager/hotelMy.vue' /* webpackChunkName: "" */))
const _40596aa9 = () => interopDefault(import('../pages/manager/airTicket.vue' /* webpackChunkName: "" */))
const _4c8a55ef = () => interopDefault(import('../pages/manager/periodicalMy.vue' /* webpackChunkName: "" */))
const _183ec366 = () => interopDefault(import('../pages/manager/fairMy.vue' /* webpackChunkName: "" */))
const _4620054c = () => interopDefault(import('../pages/manager/usersFollow.vue' /* webpackChunkName: "" */))
const _44fe069b = () => interopDefault(import('../pages/manager/usersFans.vue' /* webpackChunkName: "" */))
const _460c6545 = () => interopDefault(import('../pages/manager/auth.vue' /* webpackChunkName: "" */))
const _13eb0c67 = () => interopDefault(import('../pages/manager/updatePwd.vue' /* webpackChunkName: "" */))
const _85d9b518 = () => interopDefault(import('../pages/manager/updateInfo.vue' /* webpackChunkName: "" */))
const _66172b07 = () => interopDefault(import('../pages/manager/industryMy.vue' /* webpackChunkName: "" */))
const _c3a8863e = () => interopDefault(import('../pages/manager/visitor.vue' /* webpackChunkName: "" */))
const _7fcb2d8b = () => interopDefault(import('../pages/manager/visitorEdit.vue' /* webpackChunkName: "" */))
const _234aa7de = () => interopDefault(import('../pages/special/index.vue' /* webpackChunkName: "" */))
const _0390ef6d = () => interopDefault(import('../pages/system/index.vue' /* webpackChunkName: "" */))
const _47570da3 = () => interopDefault(import('../pages/system/error.vue' /* webpackChunkName: "" */))
const _529380e6 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "" */))
const _6fb6720e = () => interopDefault(import('../pages/booth/index.vue' /* webpackChunkName: "pages/booth/index" */))
const _1723305e = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _de9f59ca = () => interopDefault(import('../pages/about/data/index.ts' /* webpackChunkName: "pages/about/data/index" */))
const _6038ec26 = () => interopDefault(import('../pages/airTicket/helper.js' /* webpackChunkName: "pages/airTicket/helper" */))
const _28300b91 = () => interopDefault(import('../pages/airTicket/mock.js' /* webpackChunkName: "pages/airTicket/mock" */))
const _b6381ca6 = () => interopDefault(import('../pages/airTicket/pay-mixin.ts' /* webpackChunkName: "pages/airTicket/pay-mixin" */))
const _5e7a2274 = () => interopDefault(import('../pages/airTicket/service.ts' /* webpackChunkName: "pages/airTicket/service" */))
const _a77ecdd2 = () => interopDefault(import('../pages/airTicket/type.ts' /* webpackChunkName: "pages/airTicket/type" */))
const _d969c2f6 = () => interopDefault(import('../pages/airTicket/validate.js' /* webpackChunkName: "pages/airTicket/validate" */))
const _24de973a = () => interopDefault(import('../pages/booth/helper.ts' /* webpackChunkName: "pages/booth/helper" */))
const _6ef9ae1e = () => interopDefault(import('../pages/fair/detail.vue' /* webpackChunkName: "pages/fair/detail" */))
const _08538da3 = () => interopDefault(import('../pages/fair/index copy.vue' /* webpackChunkName: "pages/fair/index copy" */))
const _3047c2c0 = () => interopDefault(import('../pages/fair/index2.vue' /* webpackChunkName: "pages/fair/index2" */))
const _0bee9e38 = () => interopDefault(import('../pages/fair/periodicalList.vue' /* webpackChunkName: "pages/fair/periodicalList" */))
const _29156db8 = () => interopDefault(import('../pages/fair/player.ts' /* webpackChunkName: "pages/fair/player" */))
const _c0eb2a40 = () => interopDefault(import('../pages/fair/service.ts' /* webpackChunkName: "pages/fair/service" */))
const _d47aa4d4 = () => interopDefault(import('../pages/fair/ticket_free.ts' /* webpackChunkName: "pages/fair/ticket_free" */))
const _508e6e94 = () => interopDefault(import('../pages/fair/ticket2.vue' /* webpackChunkName: "pages/fair/ticket2" */))
const _2d0d3eb6 = () => interopDefault(import('../pages/fair/topData.ts' /* webpackChunkName: "pages/fair/topData" */))
const _2842ce5a = () => interopDefault(import('../pages/home/data/index.ts' /* webpackChunkName: "pages/home/data/index" */))
const _091a52e0 = () => interopDefault(import('../pages/login/constant.ts' /* webpackChunkName: "pages/login/constant" */))
const _ba0d09f2 = () => interopDefault(import('../pages/news/newsType.ts' /* webpackChunkName: "pages/news/newsType" */))
const _0f4a6324 = () => interopDefault(import('../pages/pay/pay-periodical-mixin.ts' /* webpackChunkName: "pages/pay/pay-periodical-mixin" */))
const _2b68a75e = () => interopDefault(import('../pages/pay/pay-ticket-mixin.ts' /* webpackChunkName: "pages/pay/pay-ticket-mixin" */))
const _b7302e44 = () => interopDefault(import('../pages/visa/helper.js' /* webpackChunkName: "pages/visa/helper" */))
const _ad57e1f0 = () => interopDefault(import('../pages/visa/pay-visa-mixin.ts' /* webpackChunkName: "pages/visa/pay-visa-mixin" */))
const _1a81a1a3 = () => interopDefault(import('../pages/visa/service.ts' /* webpackChunkName: "pages/visa/service" */))
const _4b820048 = () => interopDefault(import('../pages/visa/type.ts' /* webpackChunkName: "pages/visa/type" */))
const _58338536 = () => interopDefault(import('../pages/visa/validate.js' /* webpackChunkName: "pages/visa/validate" */))
const _de5a57ec = () => interopDefault(import('../pages/airTicket/component/Baggage.vue' /* webpackChunkName: "pages/airTicket/component/Baggage" */))
const _67dbfce6 = () => interopDefault(import('../pages/airTicket/component/CabinItem.vue' /* webpackChunkName: "pages/airTicket/component/CabinItem" */))
const _0ea69ddc = () => interopDefault(import('../pages/airTicket/component/ListFilter.vue' /* webpackChunkName: "pages/airTicket/component/ListFilter" */))
const _6e748af7 = () => interopDefault(import('../pages/airTicket/component/ListItem.vue' /* webpackChunkName: "pages/airTicket/component/ListItem" */))
const _6bdb188c = () => interopDefault(import('../pages/airTicket/component/RefundRule.vue' /* webpackChunkName: "pages/airTicket/component/RefundRule" */))
const _2ab52f25 = () => interopDefault(import('../pages/booth/components/card.vue' /* webpackChunkName: "pages/booth/components/card" */))
const _34a63cce = () => interopDefault(import('../pages/company/component/pay.vue' /* webpackChunkName: "pages/company/component/pay" */))
const _87c30198 = () => interopDefault(import('../pages/fair/component/booth.vue' /* webpackChunkName: "pages/fair/component/booth" */))
const _708a0da6 = () => interopDefault(import('../pages/fair/component/booth1.vue' /* webpackChunkName: "pages/fair/component/booth1" */))
const _0539d612 = () => interopDefault(import('../pages/fair/component/content.vue' /* webpackChunkName: "pages/fair/component/content" */))
const _146a1fb2 = () => interopDefault(import('../pages/fair/component/detailTopBg.vue' /* webpackChunkName: "pages/fair/component/detailTopBg" */))
const _601b9c41 = () => interopDefault(import('../pages/fair/component/dialogSubsidy.vue' /* webpackChunkName: "pages/fair/component/dialogSubsidy" */))
const _0d2bc7ae = () => interopDefault(import('../pages/fair/component/evaluation.vue' /* webpackChunkName: "pages/fair/component/evaluation" */))
const _765be364 = () => interopDefault(import('../pages/fair/component/FairItemSeo.vue' /* webpackChunkName: "pages/fair/component/FairItemSeo" */))
const _196d2c76 = () => interopDefault(import('../pages/fair/component/goodsDetail.vue' /* webpackChunkName: "pages/fair/component/goodsDetail" */))
const _3d98acaa = () => interopDefault(import('../pages/fair/component/invoice.vue' /* webpackChunkName: "pages/fair/component/invoice" */))
const _7819490f = () => interopDefault(import('../pages/fair/component/newDetail.vue' /* webpackChunkName: "pages/fair/component/newDetail" */))
const _b4a6275e = () => interopDefault(import('../pages/fair/component/newItem.vue' /* webpackChunkName: "pages/fair/component/newItem" */))
const _20f315ca = () => interopDefault(import('../pages/fair/component/NewsItem2.vue' /* webpackChunkName: "pages/fair/component/NewsItem2" */))
const _0af842cc = () => interopDefault(import('../pages/fair/component/orderInfo.vue' /* webpackChunkName: "pages/fair/component/orderInfo" */))
const _10fad448 = () => interopDefault(import('../pages/fair/component/payDeposit.vue' /* webpackChunkName: "pages/fair/component/payDeposit" */))
const _7c8d6b82 = () => interopDefault(import('../pages/fair/component/poster.vue' /* webpackChunkName: "pages/fair/component/poster" */))
const _24d9ca45 = () => interopDefault(import('../pages/fair/component/resourceView.vue' /* webpackChunkName: "pages/fair/component/resourceView" */))
const _94d061a6 = () => interopDefault(import('../pages/fair/component/resourceView-copy.vue' /* webpackChunkName: "pages/fair/component/resourceView-copy" */))
const _95daa7b2 = () => interopDefault(import('../pages/fair/component/Route.vue' /* webpackChunkName: "pages/fair/component/Route" */))
const _0d78ebc1 = () => interopDefault(import('../pages/fair/component/ServerStep.vue' /* webpackChunkName: "pages/fair/component/ServerStep" */))
const _6d1f7329 = () => interopDefault(import('../pages/fair/component/subsidy.vue' /* webpackChunkName: "pages/fair/component/subsidy" */))
const _3776c58b = () => interopDefault(import('../pages/fair/formData/nepcon.ts' /* webpackChunkName: "pages/fair/formData/nepcon" */))
const _fc28d8de = () => interopDefault(import('../pages/home/component/eveFair.vue' /* webpackChunkName: "pages/home/component/eveFair" */))
const _591a71ce = () => interopDefault(import('../pages/home/component/FairItem.vue' /* webpackChunkName: "pages/home/component/FairItem" */))
const _36ef02f0 = () => interopDefault(import('../pages/home/component/NewsList.vue' /* webpackChunkName: "pages/home/component/NewsList" */))
const _5feb7920 = () => interopDefault(import('../pages/login/component/bind.vue' /* webpackChunkName: "pages/login/component/bind" */))
const _644e7520 = () => interopDefault(import('../pages/login/component/code.vue' /* webpackChunkName: "pages/login/component/code" */))
const _45c56340 = () => interopDefault(import('../pages/login/component/forget.vue' /* webpackChunkName: "pages/login/component/forget" */))
const _04d355c6 = () => interopDefault(import('../pages/login/component/login.vue' /* webpackChunkName: "pages/login/component/login" */))
const _599b1564 = () => interopDefault(import('../pages/login/component/password.vue' /* webpackChunkName: "pages/login/component/password" */))
const _4b9ba556 = () => interopDefault(import('../pages/login/component/register.vue' /* webpackChunkName: "pages/login/component/register" */))
const _6332b319 = () => interopDefault(import('../pages/login/component/wechat.vue' /* webpackChunkName: "pages/login/component/wechat" */))
const _4b60cced = () => interopDefault(import('../pages/manager/components/AirTicketItem.vue' /* webpackChunkName: "pages/manager/components/AirTicketItem" */))
const _185943ee = () => interopDefault(import('../pages/manager/components/AirTicketRepayment.vue' /* webpackChunkName: "pages/manager/components/AirTicketRepayment" */))
const _14ec067d = () => interopDefault(import('../pages/manager/components/getIncoive.vue' /* webpackChunkName: "pages/manager/components/getIncoive" */))
const _77b775ab = () => interopDefault(import('../pages/manager/components/HotelItem.vue' /* webpackChunkName: "pages/manager/components/HotelItem" */))
const _5ff42aac = () => interopDefault(import('../pages/manager/components/lookIncoive.vue' /* webpackChunkName: "pages/manager/components/lookIncoive" */))
const _09c0c999 = () => interopDefault(import('../pages/manager/components/myUpload.vue' /* webpackChunkName: "pages/manager/components/myUpload" */))
const _70916d37 = () => interopDefault(import('../pages/manager/components/Repayment.vue' /* webpackChunkName: "pages/manager/components/Repayment" */))
const _4ef28d2b = () => interopDefault(import('../pages/manager/components/TicketItem.vue' /* webpackChunkName: "pages/manager/components/TicketItem" */))
const _51be8340 = () => interopDefault(import('../pages/manager/components/VisaItem.vue' /* webpackChunkName: "pages/manager/components/VisaItem" */))
const _28c14714 = () => interopDefault(import('../pages/manager/components/VisaRepayment.vue' /* webpackChunkName: "pages/manager/components/VisaRepayment" */))
const _c3692154 = () => interopDefault(import('../pages/news/component/News.vue' /* webpackChunkName: "pages/news/component/News" */))
const _11e4fe09 = () => interopDefault(import('../pages/news/component/NewsItem.vue' /* webpackChunkName: "pages/news/component/NewsItem" */))
const _64902e90 = () => interopDefault(import('../pages/news/component/RelatedNew.vue' /* webpackChunkName: "pages/news/component/RelatedNew" */))
const _2a3672a1 = () => interopDefault(import('../pages/pay/component/poster.vue' /* webpackChunkName: "pages/pay/component/poster" */))
const _3f79755d = () => interopDefault(import('../pages/visa/component/VisaMaterial.vue' /* webpackChunkName: "pages/visa/component/VisaMaterial" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _ee309a46,
    name: "首页"
  }, {
    path: "/seo",
    component: _ee309a46,
    name: "首页seo"
  }, {
    path: "/exhibition/",
    component: _2ad842e2,
    name: "国际展会"
  }, {
    path: "/exhibition-:f-:s-:g-:p-:c-:t-:page/",
    component: _2ad842e2,
    name: "展会列表"
  }, {
    path: "/seo/exhibition/",
    component: _e7cb921c,
    name: "展会列表seo"
  }, {
    path: "/seo/exhibition-:f-:s-:g-:p-:c-:t-:page/",
    component: _e7cb921c,
    name: "展会列表seo2"
  }, {
    path: "/exporec-:f-:page/",
    component: _2e69176e,
    name: "exporec"
  }, {
    path: "/wiki",
    component: _7203dc6e,
    name: "展会百科首页"
  }, {
    path: "/wiki-:s-:page/",
    component: _f2284c9c,
    name: "展会百科列表2"
  }, {
    path: "/wiki/:id-:a-:h-:aid.html",
    component: _66cac0e5,
    name: "展会百科详情页"
  }, {
    path: "/exhibition/top100.html",
    component: _2ef5f8c8,
    name: "排行榜"
  }, {
    path: "/exhibition/:id.html",
    component: _47eb8710,
    name: "exhibitionDetail"
  }, {
    path: "/seo/exhibition/:id.html",
    component: _af1897c0,
    name: "exhibitionDetail_seo"
  }, {
    path: "/pdf/:id.html",
    component: _7edb7902,
    name: "展会详情PDF"
  }, {
    path: "/exhibition/periodical/:id.html",
    component: _37d79226,
    name: "期刊详情"
  }, {
    path: "/periodical/:id.html",
    component: _bef6c8f2,
    name: "期刊详情2"
  }, {
    path: "/exhibition/apply/:id.html",
    component: _f1bd3cda,
    name: "展会申请"
  }, {
    path: "/exhibition/trip/:id.html",
    component: _31e352a3,
    name: "展团行程"
  }, {
    path: "/community/:id.html",
    component: _66617401,
    name: "用户社区"
  }, {
    path: "/expoinfo/:id.html",
    component: _48b5c18e,
    name: "展会相关资讯"
  }, {
    path: "/exhibition/proxy/:id.html",
    component: _19656c67,
    name: "代参展"
  }, {
    path: "/exhibitors/:id.html",
    component: _2ee20a6b,
    name: "参展商名录"
  }, {
    path: "/company/:id.html",
    component: _2c10b144,
    meta: {"isLogin":true},
    name: "参展商详情"
  }, {
    path: "/exhibition/ticket/:id.html",
    component: _fa58b3a8,
    name: "展会门票"
  }, {
    path: "/ticket/:id.html",
    component: _50aa9d96,
    name: "展会门票2"
  }, {
    path: "/information/",
    component: _cd13889e,
    name: "展会资讯"
  }, {
    path: "/pavilion/",
    component: _3fa5a1be,
    name: "会展中心"
  }, {
    path: "/pavilion-:f-:s-:g-:p-:c-:t-:page/",
    component: _3fa5a1be,
    name: "会展中心-筛选"
  }, {
    path: "/pavilion/:id.html",
    component: _4d61a595,
    name: "会展中心详情"
  }, {
    path: "/information-:t-:i-:page/",
    component: _cd13889e,
    name: "资讯列表"
  }, {
    path: "/information/:id.html",
    component: _31b1a1e2,
    name: "资讯详情"
  }, {
    path: "/seo/information/:id.html",
    component: _8da7d41c,
    name: "资讯详情seo"
  }, {
    path: "/exponews/:id.html",
    component: _07149dd4,
    name: "exponews"
  }, {
    path: "/seo/exponews/:id.html",
    component: _582d6b26,
    name: "exponewsSeo"
  }, {
    path: "/strategy/:id.html",
    component: _35bcd1c4,
    name: "strategy"
  }, {
    path: "/test/:id.html",
    component: _12dd603a,
    name: "newsTest"
  }, {
    path: "/cooperation.html",
    component: _ff34c65a,
    name: "展会合作"
  }, {
    path: "/visa-:visaAreaId-:areaId-:type-:bizType-:page/",
    component: _268da7bf,
    name: "签证列表"
  }, {
    path: "/visa/:id.html",
    component: _437a5fb4,
    name: "签证详情"
  }, {
    path: "/visa/submitOrder",
    component: _785a617a,
    name: "提交订单"
  }, {
    path: "/airTicket",
    component: _520948ce,
    name: "机票首页"
  }, {
    path: "/airTicket/list",
    component: _54576152,
    name: "机票列表"
  }, {
    path: "/airTicket/submitOrder",
    component: _0bcf2912,
    name: "机票提交订单"
  }, {
    path: "/airTicket/payResult/:status",
    component: _19a05361,
    name: "机票支付状态"
  }, {
    path: "/login",
    component: _b22b0bfe,
    meta: {"forbidCrawler":false},
    name: "登录"
  }, {
    path: "/expouser/:id.html",
    component: _26e9d3bb,
    name: "用户中心"
  }, {
    path: "/terms",
    component: _2c0972c2,
    name: "用户协议"
  }, {
    path: "/about/ad.html",
    component: _2cba487a,
    name: "广告服务"
  }, {
    path: "/about/:id.html",
    component: _bffc5db6,
    name: "关于我们"
  }, {
    path: "/sitemap",
    component: _4ed965e6,
    name: "网站地图"
  }, {
    path: "/payment",
    component: _88825518,
    meta: {"isLogin":true},
    name: "选择支付方式"
  }, {
    path: "/result/:status",
    component: _955c24c2,
    name: "支付状态"
  }, {
    path: "/visa-result/:status",
    component: _4609d1d2,
    name: "签证支付状态"
  }, {
    path: "/manager",
    component: _12294bf6,
    meta: {"isLogin":true},
    name: "个人中心",
    children: [{
      path: "fair-remind",
      component: _80f61eb4,
      name: "展会提醒"
    }, {
      path: "fair-follow",
      component: _50521552,
      name: "关注的展会"
    }, {
      path: "news-follow",
      component: _19e83a41,
      name: "关注的资讯"
    }, {
      path: "news-my",
      component: _6b16da88,
      name: "我的资讯"
    }, {
      path: "ticket-my",
      component: _99f67216,
      name: "我的门票"
    }, {
      path: "visa-my",
      component: _4ea2cf5e,
      name: "我的签证"
    }, {
      path: "hotel-my",
      component: _474e5df3,
      name: "我的酒店"
    }, {
      path: "air-ticket",
      component: _40596aa9,
      name: "我的机票"
    }, {
      path: "periodical-my",
      component: _4c8a55ef,
      name: "我的会刊"
    }, {
      path: "fair-my",
      component: _183ec366,
      name: "我的展会"
    }, {
      path: "usersFollow",
      component: _4620054c,
      name: "关注的用户"
    }, {
      path: "usersFans",
      component: _44fe069b,
      name: "我的粉丝"
    }, {
      path: "auth",
      component: _460c6545,
      name: "企业认证"
    }, {
      path: "update-pwd",
      component: _13eb0c67,
      name: "修改密码"
    }, {
      path: "update-info",
      component: _85d9b518,
      name: "修改资料"
    }, {
      path: "industry-my",
      component: _66172b07,
      name: "关注的行业"
    }, {
      path: "visitor",
      component: _c3a8863e,
      name: "常用参展人"
    }, {
      path: "visitor-edit/",
      component: _7fcb2d8b,
      name: "参展人信息"
    }, {
      path: "visitor-edit/:id",
      component: _7fcb2d8b,
      name: "编辑常用参展人"
    }]
  }, {
    path: "/special/:id.html",
    component: _234aa7de,
    name: "展会专题"
  }, {
    path: "/system/index",
    component: _0390ef6d,
    name: "system"
  }, {
    path: "/system/error",
    component: _47570da3,
    name: "customError"
  }, {
    path: "/404",
    component: _529380e6,
    name: "404"
  }, {
    path: "/404",
    component: _529380e6,
    name: "404"
  }, {
    path: "/about",
    component: _bffc5db6,
    name: "about"
  }, {
    path: "/airTicket",
    component: _520948ce,
    name: "airTicket"
  }, {
    path: "/booth",
    component: _6fb6720e,
    name: "booth"
  }, {
    path: "/community",
    component: _66617401,
    name: "community"
  }, {
    path: "/cooperation",
    component: _ff34c65a,
    name: "cooperation"
  }, {
    path: "/exhDetailInf",
    component: _48b5c18e,
    name: "exhDetailInf"
  }, {
    path: "/expouser",
    component: _26e9d3bb,
    name: "expouser"
  }, {
    path: "/fair",
    component: _2ad842e2,
    name: "fair"
  }, {
    path: "/home",
    component: _ee309a46,
    name: "home"
  }, {
    path: "/login",
    component: _b22b0bfe,
    name: "login"
  }, {
    path: "/manager",
    component: _12294bf6,
    name: "manager"
  }, {
    path: "/news",
    component: _1723305e,
    name: "news"
  }, {
    path: "/pavilion",
    component: _3fa5a1be,
    name: "pavilion"
  }, {
    path: "/sitemap",
    component: _4ed965e6,
    name: "sitemap"
  }, {
    path: "/special",
    component: _234aa7de,
    name: "special"
  }, {
    path: "/system",
    component: _0390ef6d,
    name: "system"
  }, {
    path: "/terms",
    component: _2c0972c2,
    name: "terms"
  }, {
    path: "/trip",
    component: _31e352a3,
    name: "trip"
  }, {
    path: "/visa",
    component: _268da7bf,
    name: "visa"
  }, {
    path: "/wiki",
    component: _7203dc6e,
    name: "wiki"
  }, {
    path: "/about/advertise",
    component: _2cba487a,
    name: "about-advertise"
  }, {
    path: "/about/data",
    component: _de9f59ca,
    name: "about-data"
  }, {
    path: "/airTicket/helper",
    component: _6038ec26,
    name: "airTicket-helper"
  }, {
    path: "/airTicket/list",
    component: _54576152,
    name: "airTicket-list"
  }, {
    path: "/airTicket/mock",
    component: _28300b91,
    name: "airTicket-mock"
  }, {
    path: "/airTicket/pay-mixin",
    component: _b6381ca6,
    name: "airTicket-pay-mixin"
  }, {
    path: "/airTicket/payResult",
    component: _19a05361,
    name: "airTicket-payResult"
  }, {
    path: "/airTicket/service",
    component: _5e7a2274,
    name: "airTicket-service"
  }, {
    path: "/airTicket/submitOrder",
    component: _0bcf2912,
    name: "airTicket-submitOrder"
  }, {
    path: "/airTicket/type",
    component: _a77ecdd2,
    name: "airTicket-type"
  }, {
    path: "/airTicket/validate",
    component: _d969c2f6,
    name: "airTicket-validate"
  }, {
    path: "/booth/helper",
    component: _24de973a,
    name: "booth-helper"
  }, {
    path: "/booth/index1",
    component: _f1bd3cda,
    name: "booth-index1"
  }, {
    path: "/company/detail",
    component: _2c10b144,
    name: "company-detail"
  }, {
    path: "/company/list",
    component: _2ee20a6b,
    name: "company-list"
  }, {
    path: "/fair/detail",
    component: _6ef9ae1e,
    name: "fair-detail"
  }, {
    path: "/fair/detail1",
    component: _47eb8710,
    name: "fair-detail1"
  }, {
    path: "/fair/detail1-seo",
    component: _af1897c0,
    name: "fair-detail1-seo"
  }, {
    path: "/fair/exporec",
    component: _2e69176e,
    name: "fair-exporec"
  }, {
    path: "/fair/index-seo",
    component: _e7cb921c,
    name: "fair-index-seo"
  }, {
    path: "/fair/index%20copy",
    component: _08538da3,
    name: "fair-index copy"
  }, {
    path: "/fair/index2",
    component: _3047c2c0,
    name: "fair-index2"
  }, {
    path: "/fair/pdf",
    component: _7edb7902,
    name: "fair-pdf"
  }, {
    path: "/fair/periodical",
    component: _37d79226,
    name: "fair-periodical"
  }, {
    path: "/fair/periodical_new",
    component: _bef6c8f2,
    name: "fair-periodical_new"
  }, {
    path: "/fair/periodicalList",
    component: _0bee9e38,
    name: "fair-periodicalList"
  }, {
    path: "/fair/player",
    component: _29156db8,
    name: "fair-player"
  }, {
    path: "/fair/proxyExh",
    component: _19656c67,
    name: "fair-proxyExh"
  }, {
    path: "/fair/service",
    component: _c0eb2a40,
    name: "fair-service"
  }, {
    path: "/fair/ticket",
    component: _fa58b3a8,
    name: "fair-ticket"
  }, {
    path: "/fair/ticket_free",
    component: _d47aa4d4,
    name: "fair-ticket_free"
  }, {
    path: "/fair/ticket1",
    component: _50aa9d96,
    name: "fair-ticket1"
  }, {
    path: "/fair/ticket2",
    component: _508e6e94,
    name: "fair-ticket2"
  }, {
    path: "/fair/top100",
    component: _2ef5f8c8,
    name: "fair-top100"
  }, {
    path: "/fair/topData",
    component: _2d0d3eb6,
    name: "fair-topData"
  }, {
    path: "/home/data",
    component: _2842ce5a,
    name: "home-data"
  }, {
    path: "/login/constant",
    component: _091a52e0,
    name: "login-constant"
  }, {
    path: "/manager/airTicket",
    component: _40596aa9,
    name: "manager-airTicket"
  }, {
    path: "/manager/auth",
    component: _460c6545,
    name: "manager-auth"
  }, {
    path: "/manager/fairFollow",
    component: _50521552,
    name: "manager-fairFollow"
  }, {
    path: "/manager/fairMy",
    component: _183ec366,
    name: "manager-fairMy"
  }, {
    path: "/manager/fairRemind",
    component: _80f61eb4,
    name: "manager-fairRemind"
  }, {
    path: "/manager/hotelMy",
    component: _474e5df3,
    name: "manager-hotelMy"
  }, {
    path: "/manager/industryMy",
    component: _66172b07,
    name: "manager-industryMy"
  }, {
    path: "/manager/newsFollow",
    component: _19e83a41,
    name: "manager-newsFollow"
  }, {
    path: "/manager/newsMy",
    component: _6b16da88,
    name: "manager-newsMy"
  }, {
    path: "/manager/periodicalMy",
    component: _4c8a55ef,
    name: "manager-periodicalMy"
  }, {
    path: "/manager/ticketMy",
    component: _99f67216,
    name: "manager-ticketMy"
  }, {
    path: "/manager/updateInfo",
    component: _85d9b518,
    name: "manager-updateInfo"
  }, {
    path: "/manager/updatePwd",
    component: _13eb0c67,
    name: "manager-updatePwd"
  }, {
    path: "/manager/usersFans",
    component: _44fe069b,
    name: "manager-usersFans"
  }, {
    path: "/manager/usersFollow",
    component: _4620054c,
    name: "manager-usersFollow"
  }, {
    path: "/manager/visa",
    component: _4ea2cf5e,
    name: "manager-visa"
  }, {
    path: "/manager/visitor",
    component: _c3a8863e,
    name: "manager-visitor"
  }, {
    path: "/manager/visitorEdit",
    component: _7fcb2d8b,
    name: "manager-visitorEdit"
  }, {
    path: "/news/detail",
    component: _31b1a1e2,
    name: "news-detail"
  }, {
    path: "/news/detail-seo",
    component: _8da7d41c,
    name: "news-detail-seo"
  }, {
    path: "/news/exponews",
    component: _07149dd4,
    name: "news-exponews"
  }, {
    path: "/news/exponews-seo",
    component: _582d6b26,
    name: "news-exponews-seo"
  }, {
    path: "/news/index2",
    component: _cd13889e,
    name: "news-index2"
  }, {
    path: "/news/newsType",
    component: _ba0d09f2,
    name: "news-newsType"
  }, {
    path: "/news/strategy",
    component: _35bcd1c4,
    name: "news-strategy"
  }, {
    path: "/news/test",
    component: _12dd603a,
    name: "news-test"
  }, {
    path: "/pavilion/detail",
    component: _4d61a595,
    name: "pavilion-detail"
  }, {
    path: "/pay/pay-periodical-mixin",
    component: _0f4a6324,
    name: "pay-pay-periodical-mixin"
  }, {
    path: "/pay/pay-ticket-mixin",
    component: _2b68a75e,
    name: "pay-pay-ticket-mixin"
  }, {
    path: "/pay/payment",
    component: _88825518,
    name: "pay-payment"
  }, {
    path: "/pay/result",
    component: _955c24c2,
    name: "pay-result"
  }, {
    path: "/system/error",
    component: _47570da3,
    name: "system-error"
  }, {
    path: "/visa/detail",
    component: _437a5fb4,
    name: "visa-detail"
  }, {
    path: "/visa/helper",
    component: _b7302e44,
    name: "visa-helper"
  }, {
    path: "/visa/pay-visa-mixin",
    component: _ad57e1f0,
    name: "visa-pay-visa-mixin"
  }, {
    path: "/visa/payResult",
    component: _4609d1d2,
    name: "visa-payResult"
  }, {
    path: "/visa/service",
    component: _1a81a1a3,
    name: "visa-service"
  }, {
    path: "/visa/submitOrder",
    component: _785a617a,
    name: "visa-submitOrder"
  }, {
    path: "/visa/type",
    component: _4b820048,
    name: "visa-type"
  }, {
    path: "/visa/validate",
    component: _58338536,
    name: "visa-validate"
  }, {
    path: "/wiki/detail",
    component: _66cac0e5,
    name: "wiki-detail"
  }, {
    path: "/wiki/list",
    component: _f2284c9c,
    name: "wiki-list"
  }, {
    path: "/airTicket/component/Baggage",
    component: _de5a57ec,
    name: "airTicket-component-Baggage"
  }, {
    path: "/airTicket/component/CabinItem",
    component: _67dbfce6,
    name: "airTicket-component-CabinItem"
  }, {
    path: "/airTicket/component/ListFilter",
    component: _0ea69ddc,
    name: "airTicket-component-ListFilter"
  }, {
    path: "/airTicket/component/ListItem",
    component: _6e748af7,
    name: "airTicket-component-ListItem"
  }, {
    path: "/airTicket/component/RefundRule",
    component: _6bdb188c,
    name: "airTicket-component-RefundRule"
  }, {
    path: "/booth/components/card",
    component: _2ab52f25,
    name: "booth-components-card"
  }, {
    path: "/company/component/pay",
    component: _34a63cce,
    name: "company-component-pay"
  }, {
    path: "/fair/component/booth",
    component: _87c30198,
    name: "fair-component-booth"
  }, {
    path: "/fair/component/booth1",
    component: _708a0da6,
    name: "fair-component-booth1"
  }, {
    path: "/fair/component/content",
    component: _0539d612,
    name: "fair-component-content"
  }, {
    path: "/fair/component/detailTopBg",
    component: _146a1fb2,
    name: "fair-component-detailTopBg"
  }, {
    path: "/fair/component/dialogSubsidy",
    component: _601b9c41,
    name: "fair-component-dialogSubsidy"
  }, {
    path: "/fair/component/evaluation",
    component: _0d2bc7ae,
    name: "fair-component-evaluation"
  }, {
    path: "/fair/component/FairItemSeo",
    component: _765be364,
    name: "fair-component-FairItemSeo"
  }, {
    path: "/fair/component/goodsDetail",
    component: _196d2c76,
    name: "fair-component-goodsDetail"
  }, {
    path: "/fair/component/invoice",
    component: _3d98acaa,
    name: "fair-component-invoice"
  }, {
    path: "/fair/component/newDetail",
    component: _7819490f,
    name: "fair-component-newDetail"
  }, {
    path: "/fair/component/newItem",
    component: _b4a6275e,
    name: "fair-component-newItem"
  }, {
    path: "/fair/component/NewsItem2",
    component: _20f315ca,
    name: "fair-component-NewsItem2"
  }, {
    path: "/fair/component/orderInfo",
    component: _0af842cc,
    name: "fair-component-orderInfo"
  }, {
    path: "/fair/component/payDeposit",
    component: _10fad448,
    name: "fair-component-payDeposit"
  }, {
    path: "/fair/component/poster",
    component: _7c8d6b82,
    name: "fair-component-poster"
  }, {
    path: "/fair/component/resourceView",
    component: _24d9ca45,
    name: "fair-component-resourceView"
  }, {
    path: "/fair/component/resourceView-copy",
    component: _94d061a6,
    name: "fair-component-resourceView-copy"
  }, {
    path: "/fair/component/Route",
    component: _95daa7b2,
    name: "fair-component-Route"
  }, {
    path: "/fair/component/ServerStep",
    component: _0d78ebc1,
    name: "fair-component-ServerStep"
  }, {
    path: "/fair/component/subsidy",
    component: _6d1f7329,
    name: "fair-component-subsidy"
  }, {
    path: "/fair/formData/nepcon",
    component: _3776c58b,
    name: "fair-formData-nepcon"
  }, {
    path: "/home/component/eveFair",
    component: _fc28d8de,
    name: "home-component-eveFair"
  }, {
    path: "/home/component/FairItem",
    component: _591a71ce,
    name: "home-component-FairItem"
  }, {
    path: "/home/component/NewsList",
    component: _36ef02f0,
    name: "home-component-NewsList"
  }, {
    path: "/login/component/bind",
    component: _5feb7920,
    name: "login-component-bind"
  }, {
    path: "/login/component/code",
    component: _644e7520,
    name: "login-component-code"
  }, {
    path: "/login/component/forget",
    component: _45c56340,
    name: "login-component-forget"
  }, {
    path: "/login/component/login",
    component: _04d355c6,
    name: "login-component-login"
  }, {
    path: "/login/component/password",
    component: _599b1564,
    name: "login-component-password"
  }, {
    path: "/login/component/register",
    component: _4b9ba556,
    name: "login-component-register"
  }, {
    path: "/login/component/wechat",
    component: _6332b319,
    name: "login-component-wechat"
  }, {
    path: "/manager/components/AirTicketItem",
    component: _4b60cced,
    name: "manager-components-AirTicketItem"
  }, {
    path: "/manager/components/AirTicketRepayment",
    component: _185943ee,
    name: "manager-components-AirTicketRepayment"
  }, {
    path: "/manager/components/getIncoive",
    component: _14ec067d,
    name: "manager-components-getIncoive"
  }, {
    path: "/manager/components/HotelItem",
    component: _77b775ab,
    name: "manager-components-HotelItem"
  }, {
    path: "/manager/components/lookIncoive",
    component: _5ff42aac,
    name: "manager-components-lookIncoive"
  }, {
    path: "/manager/components/myUpload",
    component: _09c0c999,
    name: "manager-components-myUpload"
  }, {
    path: "/manager/components/Repayment",
    component: _70916d37,
    name: "manager-components-Repayment"
  }, {
    path: "/manager/components/TicketItem",
    component: _4ef28d2b,
    name: "manager-components-TicketItem"
  }, {
    path: "/manager/components/VisaItem",
    component: _51be8340,
    name: "manager-components-VisaItem"
  }, {
    path: "/manager/components/VisaRepayment",
    component: _28c14714,
    name: "manager-components-VisaRepayment"
  }, {
    path: "/news/component/News",
    component: _c3692154,
    name: "news-component-News"
  }, {
    path: "/news/component/NewsItem",
    component: _11e4fe09,
    name: "news-component-NewsItem"
  }, {
    path: "/news/component/RelatedNew",
    component: _64902e90,
    name: "news-component-RelatedNew"
  }, {
    path: "/pay/component/poster",
    component: _2a3672a1,
    name: "pay-component-poster"
  }, {
    path: "/visa/component/VisaMaterial",
    component: _3f79755d,
    name: "visa-component-VisaMaterial"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
